import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { HTMLContent } from "../../components/Content";
import ServicesPageTemplate from "../../components/ServicesPageTemplate";
import Layout from "../../components/Layout";
import SE0 from "../../components/SEO";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const ServicesPage = ({ data }) => {
  return (
    <Layout>

      <Helmet>
        <title>{data.allContentfulServicePage.edges[0].node.metaTitle}</title>
        <meta
          name="description"
          content={data.allContentfulServicePage.edges[0].node.metaDescription}
        />
      </Helmet>
      <ServicesPageTemplate
        title={data.allContentfulServicePage.edges[0].node.title}
        content={documentToReactComponents(
          data.allContentfulServicePage.edges[0].node
            .childContentfulServicePageContentRichTextNode.json
        )}
      />
    </Layout>
  );
};

//AboutPage.propTypes = {
//  data: PropTypes.object.isRequired,
//};

export default ServicesPage;

export const servicesPageQuery = graphql`
  query servicesPageQueryReal {
    allContentfulServicePage {
      edges {
        node {
          metaTitle
          metaDescription
          title
          childContentfulServicePageContentRichTextNode {
            id
            json
          }
        }
      }
    }
  }
`;
